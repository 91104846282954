import React, { useState, useCallback, lazy, Suspense } from 'react'
import { Breadcrumb } from '@ashleynexvelsolutions/breadcrumb'
import { Coupon } from '@ashleynexvelsolutions/coupon'
import { ResponsiveLayout } from '@ashleynexvelsolutions/responsive-layout'
import { Seo2 } from '@ashleynexvelsolutions/seo2'
import { graphql } from 'gatsby'
import { Layout } from '../components/Layout'
import {
  useSeoData,
  PageSpecificSchemaFunction,
  BreadcrumbFunction,
  ReasonsToChooseFunction,
  ServiceAreasFunction,
  RecentBlogsFunction,
  CouponFunction,
  ModalFunction,
} from '../DataImports'
import { useCouponsQuery } from '../hooks/use-coupons-query'

const PageSpecificSchema = lazy(() =>
  import('@ashleynexvelsolutions/page-specific-schema').then(module => ({
    default: module.PageSpecificSchema,
  })),
)

const ReasonsToChoose = lazy(() =>
  import('@ashleynexvelsolutions/reasons-to-choose').then(module => ({
    default: module.ReasonsToChoose,
  })),
)
const ServiceAreas = lazy(() =>
  import('@ashleynexvelsolutions/service-areas').then(module => ({
    default: module.ServiceAreas,
  })),
)
const RecentBlogs = lazy(() =>
  import('@ashleynexvelsolutions/recent-blogs').then(module => ({
    default: module.RecentBlogs,
  })),
)
const Modal = lazy(() =>
  import('@ashleynexvelsolutions/modal').then(module => ({
    default: module.Modal,
  })),
)
// eslint-disable-next-line no-unused-vars
const Page = ({ data }) => {
  const { wpPage } = useCouponsQuery()
  const [formModal, setFormModal] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const clickFunction = useCallback(() => setFormModal(true), [])
  const clickFunction2 = useCallback(() => setFormModal(false), [])
  return (
    <Layout>
      <ResponsiveLayout>
        <div className="py-16">
          <Breadcrumb {...BreadcrumbFunction(data)} />

          <div className="grid lg:grid-cols-3 gap-y-10 lg:gap-10 mb-[70px]">
            {wpPage.coupons.couponsCoupons.map(coupon => {
              return <Coupon {...CouponFunction(coupon)} key={coupon.couponsContent} />
            })}
          </div>
        </div>
      </ResponsiveLayout>
      <Suspense fallback="loading">
        <ReasonsToChoose {...ReasonsToChooseFunction()} onClick={clickFunction} />
      </Suspense>
      <Suspense fallback="loading">
        <ServiceAreas {...ServiceAreasFunction()} />
      </Suspense>
      <Suspense fallback="loading">
        <RecentBlogs {...RecentBlogsFunction()} />
      </Suspense>
      <Suspense fallback="loading">
        <PageSpecificSchema {...PageSpecificSchemaFunction(data)} />
      </Suspense>
      <Suspense fallback="loading">
        <Modal {...ModalFunction().modal} isDisplayed={formModal} notDisplayed={clickFunction2} />
      </Suspense>
    </Layout>
  )
}
export const query = graphql`
  query {
    wpPage(title: { eq: "Coupons" }) {
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphUrl
        opengraphSiteName
        opengraphType
        opengraphImage {
          localFile {
            publicURL
          }
        }
        canonical
        twitterTitle
        twitterDescription
        twitterImage {
          localFile {
            publicURL
          }
        }
      }
      nexvelschemapagesposts {
        videos {
          title
          url
          description
          thumbnailImage {
            localFile {
              publicURL
            }
          }
          uploadDate
        }
        questionsAndAnswers {
          question
          answer
        }
        maps {
          mapUrl
        }
        digitaldocuments {
          title
        }
        images {
          image {
            localFile {
              publicURL
            }
            date(formatString: "LL")
            description
            title
          }
        }
        areaServedLandingPageSchema
        serviceSchema {
          serviceNameSchema
          linkToRelevantServicePageSchema
        }
        keywordsSchema {
          keywordSchema
        }
        localAwardsSchema {
          localAwardSchema
        }
        latitudeSchema
        longitudeSchema
        relevantExternalPagesSchema {
          relevantExternalPageSchema
        }
      }
      title
      uri
      breadcrumbs {
        breadcrumbsShortcode
      }
    }
    wp {
      nexvelSchemaMarkup {
        nexvelschema {
          cities {
            city
          }
          services {
            service
            linkToRelevantPageOnYourWebsite
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
export default Page
export const Head = ({ data }) => <Seo2 {...useSeoData(data)} />
